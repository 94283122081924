// import { Divider } from "@mui/material";
import React from 'react'
import { Component } from "react";
import "./Header.scss"
import logo from "../../Assets/Images/Logos/LOGOPRINCIPAL1-01-03.png";
class Header extends Component {
    state = {}
    render() {
        return (
            <div className="header-container">
                <div className="header-logo">
                    <img src={logo} alt="logo-mc" width="150"></img>
                </div>
                <div className="header-right">
                    <div className="header-description">
                        <h4>ARCHITECTURE D'INTÉRIEUR &amp; DESIGN GLOBAL</h4>
                    </div>
                </div>
            </div >
        );
    }
}
export default Header;
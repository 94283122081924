import { Divider } from '@mui/material';
import { Container, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import './App.scss';
import { Tabs } from '@mui/material';
import { Tab } from '@mui/material';
import React, { Suspense } from 'react';
import Header from './Layouts/Header/Header';
import Footer from './Layouts/Footer/Footer';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import Contact from './Pages/Contact/Contact';

function App() {
  const [value, setValue] = React.useState(
    window.location.hash.length > 0
      ? parseInt(window.location.hash.replace('#', ''))
      : 0
  );

  const handleChange = (event, newValue) => {
    window.location.hash = newValue;
    setValue(newValue);
  };

  const isMaintenance = false;

  const isDesktop = useMediaQuery('(min-width:960px)');
  const Home = React.lazy(() => import('./Pages/Home/Home'));
  const Prestations = React.lazy(() =>
    import('./Pages/Prestations/Prestations')
  );
  const About = React.lazy(() => import('./Pages/About/About'));

  return (
    <>
      <div className='App'>
        <Container maxWidth='lg'>
          <Box sx={{ height: '100%' }}>
            <Header />
            {isMaintenance === false ? (
              <>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  centered
                  variant={isDesktop ? 'standard' : 'fullWidth'}
                >
                  <Tab value={0} label='Accueil' wrapped />
                  <Tab value={1} label='A propos' wrapped />
                  <Tab value={2} label='Prestations' wrapped />
                  <Tab value={3} label='Contacts' wrapped />
                </Tabs>
                <Divider />
                <div className='content'>
                  {value !== 0 || (
                    <div className='tab-item'>
                      <Suspense fallback={<span>Loading...</span>}>
                        <Home />
                      </Suspense>
                    </div>
                  )}
                  {value !== 1 || (
                    <div className='tab-item'>
                      <Suspense fallback={<span>Loading...</span>}>
                        <div className='centered-item'>
                          <About />
                        </div>
                      </Suspense>
                    </div>
                  )}
                  {value !== 2 || (
                    <div className='tab-item'>
                      <Suspense fallback={<span>Loading...</span>}>
                        <Prestations isDesktop={isDesktop} />
                      </Suspense>
                    </div>
                  )}
                  {value !== 3 || (
                    <div className='tab-item'>
                      <Suspense fallback={<span>Loading...</span>}>
                        <Contact />
                      </Suspense>
                    </div>
                  )}
                  {isDesktop && <ScrollToTop />}
                </div>
              </>
            ) : (
              <>En maintenance...</>
            )}
          </Box>
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default App;

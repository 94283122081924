import { Email, Instagram, LinkedIn, PhoneIphone } from '@mui/icons-material';
import React from 'react';
import { Component } from 'react';
import './Contact.scss';
import image from '../../Assets/Images/CONTACT_BANNIERE.png';
import { Image } from 'mui-image';
import { Divider } from '@mui/material';
class Contact extends Component {
  state = {};

  render() {
    return (
      <>
        <div className='contact-container'>
          <Image
            src={image}
            fit='contain'
            width='100%'
            height='100%'
            showLoading={false}
            errorIcon={true}
            bgColor='rgba(194, 207, 213, 0.171)'
            duration='1000'
          />
          <div className='centered-item'>
            <div className='contact-text'>
              <div className='contact-catchphrase'>
                Un projet, une question ?<br />
                Contactez-moi.
              </div>
              <Divider />
              <a
                href='https://www.linkedin.com/in/manon-m%C3%A9tayer-calcagni-b93898185/'
                style={{ textDecoration: 'none' }}
              >
                <div className='contact-link-line'>
                  <LinkedIn className='contact-link-line-icon' />
                  Manon Métayer Calcagni
                </div>
              </a>
              <a
                href='https://www.instagram.com/ateliermcarchitecture/'
                style={{ textDecoration: 'none' }}
              >
                <div className='contact-link-line'>
                  <Instagram className='contact-link-line-icon' />
                  Atelier MC
                </div>
              </a>
              <a href='tel:0765537096' style={{ textDecoration: 'none' }}>
                <div className='contact-link-line'>
                  <PhoneIphone className='contact-link-line-icon' />
                  07 65 53 70 96
                </div>
              </a>
              <a
                href='mailto: contact@ateliermc-architecte.fr'
                style={{ textDecoration: 'none' }}
              >
                <div className='contact-link-line'>
                  <Email className='contact-link-line-icon' />
                  contact@ateliermc-architecte.fr
                </div>
              </a>
              <Divider />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Contact;

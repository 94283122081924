import { Component } from 'react';
import React from 'react'
import './Footer.scss';
import { Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

    render() {
        return (<>
            <div className="footer-container">
                © 2022 Tous droits réservés | ATELIER MC |
                <div className="mentionsLegalesCTA" onClick={() => this.setState({ open: true })}>Mentions légales</div>
            </div>
            <Dialog
                open={this.state.open}
                onClose={() => this.setState({ open: false })}
                maxWidth={'xl'}
            >
                <DialogTitle>MENTIONS LEGALES</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText tabIndex={-1}>
                        IDENTITE DE L’EDITEUR
                        <br /><br />
                        Le site www.ateliermc-architecte.fr est exploité par l’entreprise individuelle AtelierMC ; auto-entrepreneur.
                        Activité de Conception et conseil en architecture d’intérieur, aménagement, design, agencement et
                        décoration. Représentée par Manon Métayer, 15 Rue Charles de Gaulle 69520 Grigny.
                        Siret n° 9136 698 830 0013. TVA non applicable art,293 B du CGI. Téléphone : +33 (0)7 65 53 70 96.
                        Mail : contact@ateliermc-architecte.fr
                        <br /><br />
                        IDENTITE DE L’HÉBERGEUR
                        <br /><br />
                        Le site www.ateliermc-architecte.fr est hébergé par www.ionos.fr : IONOS SARL (7, place de la Gare, BP
                        70109, 57200 Sarreguemines Cedex).
                        <br /><br />
                        GESTION DES DONNEES PERSONNELLES
                        <br /><br />
                        A l’occasion de l’utilisation du site www.ateliermc-architecte.fr, aucune donnée n’est recueillie par
                        AtelierMC. Tout client dispose d’un droit d’accès, de rectification et d’opposition aux données
                        personnelles le concernant, en effectuant sa demande écrite par un simple email. Aucune information
                        personnelle de l’utilisateur du site www.ateliermc-architecte.fr n’est publiée à l’insu du client, échangée,
                        transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l’hypothèse du rachat de
                        l’entreprise individuelle AtelierMC et de ses droits permettrait la transmission des dites informations à
                        l’éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification
                        des données vis à vis de l’utilisateur du site www.ateliermc-architecte.fr
                        <br /><br />
                        RESPONSABILITE
                        <br /><br />
                        AtelierMC décline toute responsabilité pour d’éventuels dommages directs ou indirects pouvant résulter
                        de l’utilisation ou de la consultation de son site.
                        AtelierMC ne saurait donc voir sa responsabilité engagée pour toute information(qui demeurent en tous
                        temps non - contractuelles), erreur matérielle, ou encore pour tous inconvénients ou dommages inhérents
                        à l’utilisation du réseau Internet, ceci inclus les ruptures de services(pour cause de maintenance, force
                        majeure, tel que ce terme est défini par la jurisprudence en vigueur, ou autre), la présence de virus
                        informatiques et autres nuisances assimilées.
                        Pour toute question ou demande de rectification d’informations relative au contenu du site, l’utilisateur est
                        invité à adresser un courrier électronique à l’adresse suivante: contact@ateliermc-architecte.fr
                        <br /><br />
                        RESPECT DES DROITS D’AUTEUR
                        <br /><br />
                        L’intégralité du site: logos, images, illustrations, textes… contenus sur ce site sont la propriété exclusive
                        de l’entreprise individuelle AtelierMC.Toute reproduction partielle ou totale du présent site et des éléments
                        qu’il contient constitue une contrefaçon et est strictement interdite sans autorisation écrite et préalable de
                        AtelierMC ou du tiers détenteur, sous peine de poursuites.
                    </DialogContentText >
                </DialogContent >
                <DialogActions>
                    <Button onClick={() => this.setState({ open: false })}>Fermer</Button>
                </DialogActions>
            </Dialog >
        </>);
    }
}

export default Footer;
